<!DOCTYPE html>
<html lang="en"
  class="notranslate"
  translate="no">

<head>
  <meta charset="utf-8">
  <meta name="viewport"
    content="width=device-width, 
               initial-scale=1">
</head>

<body>
  <div class="top">
    <h1>Bestellzusammenfassung</h1>
  </div>
  <div class="allgemein">
    <div class="flex-container">
      <div class="flex-item-left">
        <h2 id="headline">Ihre Rechnungsadresse lautet:</h2>
        <div class="container">
          <div id="left">Anrede:</div>
          <div id="right">{{this.adresse.anrede}}</div>
          <div id="left">Vorname:</div>
          <div id="right">{{this.adresse.vorname}}</div>
          <div id="left">Nachname:</div>
          <div id="right">{{this.adresse.nachname}}</div>
          <div id="left">Anschrift:</div>
          <div id="right">{{this.adresse.adresse}}</div>
          <div id="left"
            *ngIf="this.adresse.adresszusatz!=''">Adresszusatz:</div>
          <div id="right"
            *ngIf="this.adresse.adresszusatz!=''">{{this.adresse.adresszusatz}}</div>
          <div id="left">E-Mail Adresse:</div>
          <div id="right">{{this.adresse.email}}</div>
          <div id="left">PLZ:</div>
          <div id="right">{{this.adresse.plz}}</div>
          <div id="left">Stadt:</div>
          <div id="right">{{this.adresse.stadt}}</div>
          <div id="left">Land:</div>
          <div id="right">{{this.adresse.land}}</div>
          <div id="left"
            *ngIf="this.adresse.notiz!=''">Produkt-Personalisierung:</div>
          <div id="right"
            *ngIf="this.adresse.notiz!=''">{{this.adresse.notiz}}</div>
        </div>
        <div *ngIf="this.lieferung != null || this.lieferung != undefined">
          <h2 id="headline">Ihre Lieferadresse lautet:</h2>
          <div class="container">
            <div id="left">Anrede:</div>
            <div id="right">{{this.lieferung.anrede}}</div>
            <div id="left">Vorname:</div>
            <div id="right">{{this.lieferung.vorname}}</div>
            <div id="left">Nachname:</div>
            <div id="right">{{this.lieferung.nachname}}</div>
            <div id="left">Anschrift:</div>
            <div id="right">{{this.lieferung.adresse}}</div>
            <div id="left"
              *ngIf="this.lieferung.adresszusatz!=''">Adresszusatz:</div>
            <div id="right"
              *ngIf="this.lieferung.adresszusatz!=''">{{this.lieferung.adresszusatz}}</div>
            <div id="left">PLZ:</div>
            <div id="right">{{this.lieferung.plz}}</div>
            <div id="left">Stadt:</div>
            <div id="right">{{this.lieferung.stadt}}</div>
            <div id="left">Land:</div>
            <div id="right">{{this.lieferung.land}}</div>
          </div>
        </div>
      </div>

      <div class="flex-item-right">
        <h2 id="headline">Zahlungs- und Versandinformationen</h2>
        <mat-radio-group [(ngModel)]="zahlungsart">
          <div class="container">
            <div id="left">Zahlungsart:</div>
            <div style="width:50px;">
              <mat-radio-button value="paypal">PayPal</mat-radio-button>
              <mat-radio-button value="vorkasse">Vorkasse</mat-radio-button>
              <mat-radio-button value="zahlungabholung">Zahlung bei Abholung</mat-radio-button>
            </div>
          </div>
        </mat-radio-group>
        <p></p>
        <mat-radio-group [(ngModel)]="versandart">
          <div class="container">
            <div id="left">Versandart:</div>
            <div style="width:50px;">
              <mat-radio-button value="versand"
                [disabled]="zahlungsart==='zahlungabholung'">DHL-Standardversand</mat-radio-button>
              <mat-radio-button value="abholung">Abholung</mat-radio-button>
            </div>
          </div>
        </mat-radio-group>
      </div>
      <div class="flex-item-unten-left">
        <button mat-button
          style="color:black;"
          (click)="navigatebestellabschluss();">Zurück zur Übersicht</button>
      </div>
      <div class="flex-item-unten-right">
        <form class="contact-form"
          (submit)="jetztkaufen()">
          <div [ngClass]="{'buttondisabled': zahlungsart === undefined || versandart === undefined}">
            <button mat-button
              style="color:black;"
              *ngIf="zahlungsart!='paypal'"
              type="submit"
              value="Send">Jetzt kaufen</button>
          </div>
        </form>
        <div [ngClass]="{'buttondisabled': zahlungsart === undefined || versandart === undefined}">
          <ngx-paypal *ngIf="zahlungsart==='paypal'"
            [config]="payPalConfig"></ngx-paypal>
        </div>
      </div>
    </div>
  </div>
</body>

</html>