import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widerrufsbelehrung',
  templateUrl: './widerrufsbelehrung.component.html',
  styleUrls: ['./widerrufsbelehrung.component.css']
})
export class WiderrufsbelehrungComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
