<!DOCTYPE html>
<html lang="de"
  class="notranslate"
  translate="no">

<head>
  <meta charset="utf-8">
  <meta name="viewport"
    content="width=device-width, 
               initial-scale=1">
</head>

<body>
  <div class="flex-container">
    <div class="flex-item-left"
      id="oben-links">
      <img mat-card-image
        src="./assets/images/Christine.jpg"
        alt="Bild kann nicht geladen werden"
        id="picture1">
    </div>
    <div class="flex-item-right"
      id="oben-rechts">
      <div class="text">
        <h2>Leidenschaftlich kreativ</h2>
        <p>Jeder fängt mal klein an - so wie wir, meine Nichte Bianca und ich.
          Begonnen hat alles mit Windowcolor und Tontopffiguren mit denen wir im
          Jahr 2001 das erste Mal auf unserem kleinen Weihnachtsmarkt im Ort standen.
          2 Jahre später hielt ich das erste Buch über Laubsägearbeiten in der Hand und war
          Feuer und Flamme. Seit dem erstellen wir in unserer Freizeit Holzdekorationen
          und sind ca. 4 bis 5 mal im Jahr auf Kunsthandwerkermärkten zu finden.
          Im Jahr 2019 fanden wir auch an Betondekorationen gefallen und haben 2020
          unser Programm damit erweitert.<br>
        <p><strong>Jedes Stück ist mit Liebe zum Detail in Handarbeit gefertigt.</strong></p>
        <h4>Das ist unser Ausgleich neben Familie und Beruf. :-)</h4>
        <p>Bisher hatten wir nur eine Homepage mit unseren entstandenen Werken auf
          <strong>www.chrisubis.beepworld.de</strong>
        <p>Da die Frage, warum wir keinen Shop haben, immer öfter gestellt wurde:</p>
        <p><strong>HIER IST ER.</strong></p>
      </div>
    </div>
  </div>

  <div class="flex-container">
    <div class="flex-item-left"
      id="mitte-links">
      <div class="text">
        <h2>Onlineshop</h2>
        <p>Im Shop bieten wir Figuren mit und ohne Beleuchtung an.
          Schlummerlampen für die lieben Kleinen sowie Holzsäulen,
          Kantholzdekorationen und Holzaufsteller - Dekostelen.
          Des weiteren verschiedene Betonartikel.
          Für jede Jahreszeit ist etwas dabei.
          Auch individuelle Wünsche werden berücksichtigt.</p>
        <p>Ab 50,00 Euro Warenwert liefern wir VERSANDKOSTENFREI!</p>
        <h4>Viel Spaß beim Stöbern</h4>
        <p>Die meisten Laubsägemotive stammen aus der TOPP Reihe des Frech Verlages.</p>
      </div>
    </div>
    <div class="flex-item-right"
      id="mitte-rechts">
      <img mat-card-image
        src="./assets/images/Bianca.jpg"
        alt="Bild kann nicht geladen werden"
        id="picture1">
    </div>
  </div>

  <div class="flex-container">
    <div class="flex-item-right"
      id="unten-links">
      <img mat-card-image
        src="./assets/images/Materialien.jpg"
        alt="Bild kann nicht geladen werden"
        id="picture3">
      <div class="test">
        <h3>Verwendete Materialien</h3>
        <p>Pappelsperrholz ( 4 mm bis 12 mm ),</p>
        <p>Fichte-Leimholz ( 18 mm ),</p>
        <p>Multiplexplatten wasserfest ( 10 mm),</p>
        <p>Holzsäulen Fichte gehobelt in verschiedenen Stärken und Birkenstämme,</p>
        <p>Feinbeton, Raysin 200, Keramin S.,</p>
        <p>Permanent Vinylfolie, Edding Permanentspray.,</p>
        <p>Acrylfarben, Seidenmatt-Lack Spray auf Acrylbasis</p>
      </div>
    </div>
    <div class="flex-item-right"
      id="unten-rechts">
      <img mat-card-image
        src="./assets/images/Pavillion.jpg"
        alt="Bild kann nicht geladen werden"
        id="picture3">
      <div class="test">
        <h3>Veranstaltungen an denen wir teilnehmen</h3>
        <p><strong>10.03.2024<br>Frühlingsbasar im Hansesaal Lünen, Kurt-Schuhmacher-Str. 41</strong></p>
        <p><strong>05.05.2024<br>Brami Frühlingsfest Waltroper Straße 44536 Lünen</strong></p>
        <p><strong>26.05.2024<br>Kunst-, und Kulinarikmarkt Henrichenburger Straße 66-68, 45665 Recklinghausen</strong>
        </p>
        <p><strong>20-21.07.2024<br>Seppenrader Sommermarkt im Rosengarten Am Rosengarten 6, 59438 Lüdinghausen</strong>
        </p>
        <p><strong>31.08-01.09.2024<br>Aplerbecker Apfelmarkt, 44287 Dortmund, Rodenbergstr. 36, 44287 Dortmund</strong>
        </p>
        <p><strong>06.10.2024<br>Kunst-, und Kulinarikmarkt Henrichenburger Straße 66-68, 45665 Recklinghausen</strong>
        </p>
        <p><strong>30.11-01.12.2024<br>Weihnachtsbasar im Hansesaal Lünen, Kurt-Schuhmacher-Str. 41</strong></p>

      </div>
    </div>
  </div>
</body>

</html>