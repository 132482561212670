<!DOCTYPE html>
<html lang="de" class="notranslate" translate="no">    
<head>
<meta charset="utf-8">
<meta name="viewport" 
      content="width=device-width, 
               initial-scale=1">
</head>
<body>
  <div class="allgemein">
<div class="Überschrift">
    <h1>Impressum</h1>
  </div>
  <mat-card class="example-card" fxLayoutAlign="center center">
    <mat-card-content>
              <p><strong><em>Christine Uhe</em></strong><br>
              <strong><em>Hedwigstraße 10a</em></strong><br>
              <strong><em>45711 Datteln</em></strong><br>
              <strong><em>Tel.: 02363 / 7289056</em></strong><br>
              <strong><em>Fax: 02363 / 7289057</em></strong><br>
              <strong><em>Mobil: 0171 / 6975330</em></strong><br>
              <strong><em>E-Mail: shop@chrisubis-hobby.de</em></strong><br></p>
              <p><strong>Alle auf dieser Homepage veröffentlichten Bilder unterliegen meinem "Copyright".</strong></p>
          
              <p>1. Haftungsbeschränkung<br>
              Inhalte dieser Website<br>
              Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt. Der Anbieter übernimmt jedoch<br>
              keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte. Die Nutzung<br>
              der Inhalte der Website erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete Beiträge geben<br>
              die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder.<br>
              Verfügbarkeit der Website<br>
              Der Anbieter wird sich bemühen, den Dienst möglichst unterbrechungsfrei zum Abruf anzubieten. Auch<br>
              bei aller Sorgfalt können aber Ausfallzeiten nicht ausgeschlossen werden. Der Anbieter behält sich das<br>
              Recht vor, sein Angebot jederzeit zu ändern oder einzustellen.<br>
              Externe Links<br>
              Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen<br>
              der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen<br>
              Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt<br>
              waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige<br>
              Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass<br>
              sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige<br>
              Kontrolle dieser externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht<br>
              zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich<br>
              gelöscht.<br>
              Werbeanzeigen<br>
              Für den Inhalt der Werbeanzeigen ist der jeweilige Autor verantwortlich, ebenso wie für den Inhalt der<br>
              beworbenen Website. Die Darstellung der Werbeanzeige stellt keine Akzeptanz durch den Anbieter dar.<br>
              Kein Vertragsverhältnis<br>
              Mit der Nutzung der Website des Anbieters kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und<br>
              dem Anbieter zustande. Insofern ergeben sich auch keinerlei vertragliche oder quasivertragliche Ansprüche<br>
              gegen den Anbieter.<br>
              2. Urheberrecht<br>
              Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheberrecht.<br>
              Jede vom deutschen Urheberrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung<br>
              des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung,<br>
              Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen<br>
              elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet.<br>
              Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet<br>
              und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und<br>
              nicht kommerziellen Gebrauch ist erlaubt.<br>
              Links zur Website des Anbieters sind jederzeit willkommen und bedürfen keiner Zustimmung durch den<br>
              Anbieter der Website. Die Darstellung dieser Website in fremden Frames ist nur mit Erlaubnis zulässig.<br>
              3. Datenschutz<br>
              Durch den Besuch der Website des Anbieters können Informationen über den Zugriff (Datum, Uhrzeit,<br>
              betrachtete Seite) auf dem Server gespeichert werden. Diese Daten gehören nicht zu den<br>
              personenbezogenen Daten, sondern sind anonymisiert. Sie werden ausschließlich zu statistischen Zwecken<br>
              ausgewertet. Eine Weitergabe an Dritte, zu kommerziellen oder nichtkommerziellen Zwecken, findet nicht<br>
              statt.<br>
              Der Anbieter weist ausdrücklich darauf hin, dass die Datenübertragung im Internet (z.B. bei der<br>
              Kommunikation per E-Mail) Sicherheitslücken aufweisen und nicht lückenlos vor dem Zugriff durch<br>
              Dritte geschützt werden kann.<br>
              Die Verwendung der Kontaktdaten des Impressums zur gewerblichen Werbung ist ausdrücklich nicht<br>
              erwünscht, es sei denn der Anbieter hatte zuvor seine schriftliche Einwilligung erteilt oder es besteht bereits<br>
              eine Geschäftsbeziehung. Der Anbieter und alle auf dieser Website genannten Personen widersprechen<br>
              hiermit jeder kommerziellen Verwendung und Weitergabe ihrer Daten.<br>
              4. Anwendbares Recht<br>
              Es gilt ausschließlich das maßgebliche Recht der Bundesrepublik Deutschland.<br>
              Quelle: <a href="http://www.Juraforum.de/">www.Juraforum.de</a> - Disclaimer, Urteile, Rechtsanwälte, Gesetze & Lexikon</p>
            </mat-card-content>
            </mat-card>
          </div>
            </body>
            </html>