<!DOCTYPE html>
<html lang="de" class="notranslate" translate="no">    
<head>
<meta charset="utf-8">
<meta name="viewport" 
      content="width=device-width, 
               initial-scale=1">
</head>
<body>
  <div class="allgemein">
  <div class="Überschrift">
     <h1>Versand- und Zahlungsinformationen</h1> 
  </div>
  <mat-card class="example-card" fxLayoutAlign="center center">
    <mat-card-content>
    <h2>Allgemeine Versandinformationen</h2>
  <p>In der folgenden Tabelle findest du Versandoptionen, Versandkosten und Lieferzeiten.</p>
  <h4>Versandoptionen</h4>
    <table id="coll">
      <tbody>
        <tr>
          <th>Lieferoptionen</th>
          <th>Kosten</th>
          <th>Lieferzeiten - Vorrätige Artikel</th>
        </tr>
        <tr>
          <td>DHL-Standardversand</td>
          <td>5,00 €</td>
          <td>1 - 3 Werktage</td>
        </tr>
        <tr>
          <td>Abholung</td>
          <td>0,00 €</td>
          <td>Nach Terminvereinbarung</td>
        </tr>
      </tbody>
    </table>
  <h4>Die Lieferzeiten bei nicht vorrätigen Artikeln betragen:</h4>
  <p>bis ca. 14 Werktage</p>
  <h4>Länder, in die wir versenden</h4>
  <p>Deutschland</p>
  <p>Auf Anfrage ist der Versand in andere Länder möglich.</p>



  <h2>Zahlungsinformationen</h2>

  <p>
    <b>PayPal</b></p>
  <p>
    Schnell einfach und sicher
  </p>
  <p>
    <b>Vorkasse</b>
  </p>
  <p>
    Überweisen Sie uns einfach den zu zahlenden Betrag. Sobald die Zahlung bei uns eingegangen ist,<br> versenden wir mit DHL an Sie mit Angabe der Sendungsverfolgungsnummer.<br>
    Bankverbindung:</p>
    <p>
    Sparkasse Vest Recklinghausen<br>
    Kontoinhaber: Christine Uhe<br>
    IBAN: DE 06 4265 0150 1000 6069 37<br>
    BIC: WELADED1REK<br>
    Bitte geben Sie unbedingt Ihren vollständigen Namen als Verwendungszweck an.<br>
  </p>



</mat-card-content>
<!-- <mat-card-content>
  <h2>Zahlungsinformationen</h2>
  <p>
    PayPal 
Schnell einfach und sicher

Vorkasse
Überweisen Sie uns einfach den zu zahlenden Betrag. Sobald die Zahlung bei uns eingegangen ist, versenden wir mit DHL an Sie mit Angabe der Sendungsverfolgungsnummer.
Bankverbindung:
Sparkasse Vest Recklinghausen
Kontoinhaber: Christine Uhe
IBAN: DE 06 4265 0150 1000 6069 37
BIC: WELADED1REK
Bitte geben Sie unbedingt Ihren vollständigen Namen als Verwendungszweck an.

  </p>
</mat-card-content> -->
</mat-card>
</div>
  </body>
  </html>