<mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation">
        <app-navigation (sidenavClose)="sidenav.close()"></app-navigation>
    </mat-sidenav>
    <mat-sidenav-content>
        <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
          <router-outlet></router-outlet>
          <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
