<!DOCTYPE html>
<html lang="de" class="notranslate" translate="no">    
<head>
<meta charset="utf-8">
<meta name="viewport" 
      content="width=device-width, 
               initial-scale=1">
</head>
<body>
  <div class="Bewertung">
  <form>
  <div class="headline">
    <h1>Bewertungserstellung</h1>
  </div>
  <p>
    <mat-form-field class="anrede" appearance="fill">
      <mat-label>Anrede</mat-label>
      <mat-select name="anrede" required="required" [(ngModel)]="this.bewertung.anrede" autocomplete="off">
        <mat-option selected>Bitte auswählen</mat-option>
        <mat-option value="Herr">Herr</mat-option>
        <mat-option value="Frau">Frau</mat-option>
        <mat-option value="Divers">Divers</mat-option>
      </mat-select>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field class="unten" appearance="fill">
      <mat-label>Vorname</mat-label>
      <input matInput name="vorname" required="required" [(ngModel)]="this.bewertung.vorname" autocomplete="off">
    </mat-form-field>
  </p>
  <p>
    <mat-form-field class="unten" appearance="fill">
      <mat-label>Nachname</mat-label>
      <input matInput name="nachname" required="required" [(ngModel)]="this.bewertung.nachname" autocomplete="off">
    </mat-form-field>
  </p>
  <p>
    <mat-form-field class="unten" appearance="fill">
      <mat-label>Anzeigename</mat-label>
      <input matInput name="synonym" [(ngModel)]="this.bewertung.synonym" autocomplete="off">
    </mat-form-field>
  </p>
  <p>
    <mat-form-field class="unten" appearance="fill">
      <mat-label>E-Mail</mat-label>
      <input matInput name="email" required="required" [(ngModel)]="this.bewertung.email" autocomplete="off">
    </mat-form-field>
    </p>
    <div class="form-group">
      <mat-form-field class="Nachricht">
        <mat-label>Ihre Bewertung:</mat-label>
        <textarea matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="20"
                  autocomplete="off"
                  type="textarea"
                  name="nachricht"
                  required="required"
                  [(ngModel)]="this.bewertung.bewertung"></textarea>
      </mat-form-field>
    </div>
      <div class="form-group">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="gridCheck" (change)="toggleEditable($event)">
          <label class="form-check-label" for="gridCheck">
            Ich bin mit der Verarbeitung meiner Daten zur Veröffentlichung der Bewertung einverstanden.
          </label>
        </div>
      </div>
      <div class="form-group">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="synonym" (change)="tooglesynonym($event)">
          <label class="form-check-label" for="gridCheck">
            Nur den Anzeigenamen veröffentlichen.
          </label>
        </div>
      </div>
      <button type="submit" class="btn" (click) ="wertenichtleer(bewertung);"  style="float: right;">Absenden</button>
</form>
</div>
</body>
</html>