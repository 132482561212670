<!DOCTYPE html>
<html lang="de" class="notranslate" translate="no">    
<head>
<meta charset="utf-8">
<meta name="viewport" 
      content="width=device-width, 
               initial-scale=1">
</head>
<body>
  <div class="allgemein">
<div class="Überschrift">
  <h1>Kontaktformularbestätigung</h1>
</div>
<mat-card class="example-card">
          <mat-card-content>
            <p>Vielen Dank für Ihre Kontaktanfrage auf Chrisubis-Hobby.de</p>
            <p>Wir werden Ihr Anliegen schnellstmöglich bearbeiten und uns mit Ihnen in Verbindung setzen.</p>
          </mat-card-content>
  </mat-card>
  </div>
  <div class="flex-container">
    <div class="flex-item-unten-left">
      <button mat-button style ="color:black;" (click) = "navigatezurueck();">Zurück zum Shop</button>
    </div>
  </div>
  </body>
  </html>