<!DOCTYPE html>
<html lang="en" class="notranslate" translate="no">    
<head>
<meta charset="utf-8">
<meta name="viewport" 
      content="width=device-width, 
               initial-scale=1">
</head>
<body>
<div class="allgemein">
    <h1>Information zu den Schlummerlampen:</h1>
    <div class="text">
    <p>
        Alle Lampen können wahlweise mit unterschiedlichen Lichterketten bestellt werden.
    </p>
    <p>
        10er Lichterkette warm-weiß mit Ein/Aus Schalter Lampenpreis 14,90€ (Elsa 15,90€)
    </p>
    <p>
        10er LED-Lichterkette warm-weiß mit Ein/Aus Schalter Lampenpreis 16,90€ (Elsa 17,90€)
    </p>
    <p>
        10er LED-Lichterkette warm-weiß Batteriebetrieben Lampenpreis inkl. Batterien 17,90€ (Elsa 18,90€)
    </p>
    <p>
        Seit dem 01.01.2021 fertigen wir alle Lampen mit einer LED-Lichterkette. Noch vorhandene einzelne Lampen haben eine herkömmliche Lichterkette.
    </p>
    <p>
        Rückansicht der Lampen (Beispiel)
    </p>
    <p>
        <img mat-card-image src="./assets/images/Schlummerlampen/Eiskoenigin_Elsa_zwei.jpg" alt="Bild kann nicht geladen werden" id="picture3"> 
    </p>
    <p>
        Farben passend zur Vorderansicht.
    </p>
</div>
    <p>
        <button mat-button style ="color:black;" (click) ="navigateHome();">Zurück zum Produkt</button>
      </p>
</div>
</body>
</html>