<!DOCTYPE html>
<html lang="de" class="notranslate" translate="no">    
<head>
<meta charset="utf-8">
<meta name="viewport" 
      content="width=device-width, 
               initial-scale=1">
</head>
<body>
  <div class="allgemein">
<div class="Überschrift">
  <h1>Bewertungseingangsbestätigung</h1>
</div>
<mat-card class="example-card">
          <mat-card-content>
            <p>Vielen Dank für Ihre Bewertung von Chrisubis-Hobby.de</p>
            <p>Nach erfolgreicher Prüfung der Bewertung wird diese auf chrisubis-hobby.de veröffentlicht.</p>
          </mat-card-content>
  </mat-card>
  </div>
  <div class="flex-container">
    <div class="flex-item-unten-left">
        <button mat-button style ="color:black;" (click) = "navigateshop();">Zum Shop</button>
      </div>
    <div class="flex-item-unten-right">
      <button mat-button style ="color:black;" (click) = "navigatezurueck();">Zu allen Bewertungen</button>
    </div>
  </div>
  </body>
  </html>