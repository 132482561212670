<!DOCTYPE html>
<html lang="de" class="notranslate" translate="no">    
<head>
<meta charset="utf-8">
<meta name="viewport" 
      content="width=device-width, 
               initial-scale=1">
</head>
<body>
  <div class="allgemein">
<div class="Überschrift">
  <h1>AGB</h1>
</div>
<mat-card class="example-card" fxLayoutAlign="center center">
          <mat-card-content>
              <h2>§1 Geltung gegenüber Firmen und Begriffsdefinitionen</h2>
              <p>(1) Die im Folgenden genannten Allgemeinen Geschäftsbedingungen beziehen sich auf alle<br>    
              Lieferungen, die zwischen uns und einem Verbraucher stattfinden. Es die zum jeweiligen Zeitpunkt<br>  
              gültige Fassung zu beachten.</p>
              
              <p>Als Verbraucher ist jede natürliche Person zu bezeichnen, die Rechtsgeschäfte zu Zwecken abschließt,<br> 
              die hauptsächlich weder ihrer selbständig beruflichen oder gewerblichen Beschäftigung zugerechnet<br> 
              werden können (siehe § 13 BGB).</p> 
              
              <h2>§2 Vertragsabschluss, Speicherung des Vertragsinhalts</h2>  
              <p>(1) Die folgenden Vorgaben zum Zustandekommen eines Vertrages beziehen sich auf alle Bestellungen, die<br>  
              über den Onlineshop http://chrisubis-hobby.de erfolgen.<br>  
              (2) Beim Abschluss kommt es zum Vertrag mit:<br></p>
              
              <p>Chrisubis-Hobby<br> 
              Christine Uhe<br> 
              Hedwigstr. 10a<br> 
              45711 Datteln<br></p>
              
              <p>(3) Die Präsentation der Waren stellt eine unverbindliche Aufforderung an den Verbraucher dar,<br> 
              Waren zu bestellen. Es ist kein rechtlich bindendes Vertragsangebot unsererseits. Kommt es zur<br> 
              Bestellung, gibt der Verbraucher ein für ihn verbindlichen Angebot auf den Vertragsabschluss ab.<br> 
              (4) Trifft eine Bestellung zu unserem Internetshop bei uns ein, sind folgende Regelungen zu beachten:<br> 
              Der Verbraucher reicht ein bindendes Vertragsangebot ein, indem er die Bestellprozedur in unserem<br> 
              Shop erfolgreich durchläuft.</p> 
          </mat-card-content>
  </mat-card>
  </div>
  </body>
  </html>