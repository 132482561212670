<!DOCTYPE html>
<html lang="en"
    class="notranslate"
    translate="no">

<head>
    <meta charset="utf-8">
    <meta name="viewport"
        content="width=device-width, 
               initial-scale=1">
</head>

<body>
    <div class="allgemein">
        <h1>Willkommen im Online-Shop</h1>

        <div class="flex-container">
            <div class="flex-item-left">
                <img mat-card-image
                    src="./assets/images/Betonlampen/20210314133643.jpg"
                    alt="Bild kann nicht geladen werden"
                    (click)="navigateHolz('Beton');"
                    id="picture3">
                <mat-card-content (click)="navigateHolz('Beton');"
                    id="unten">
                    <p>
                        Beton
                    </p>
                </mat-card-content>
            </div>
            <div class="flex-item-right">
                <img mat-card-image
                    src="./assets/images/Holzfiguren/Froschpaercheneins.jpg"
                    alt="Bild kann nicht geladen werden"
                    (click)="navigateHolz('Holz');"
                    id="picture3">
                <mat-card-content (click)="navigateHolz('Holz');"
                    id="unten">
                    <p>
                        Holz
                    </p>
                </mat-card-content>
            </div>
            <!-- <div class="flex-item-mitte-left">
        <img mat-card-image src="./assets/images/Keraminfiguren/Keramin Pilz klein.JPG" alt="Bild kann nicht geladen werden" (click) = "navigateHolz('Keramin');" id="picture3">
        <mat-card-content (click) = "navigateHolz('Keramin');" id="unten">
            <p>
                Keramin
            </p>
        </mat-card-content>
    </div> -->
            <div class="flex-item-mitte-right">
                <img mat-card-image
                    src="./assets/images/Raysinfiguren/Haeuserkranz1.jpg"
                    alt="Bild kann nicht geladen werden"
                    (click)="navigateRaysin('Raysin');"
                    id="picture3">
                <mat-card-content (click)="navigateRaysin('Raysin');"
                    id="unten">
                    <p>
                        Raysin-Keraflott
                    </p>
                </mat-card-content>
            </div>
            <div class="flex-item-mitte-right">
                <img mat-card-image
                    src="./assets/images/Klotz/LichtboxAnkerplatz.jpg"
                    alt="Bild kann nicht geladen werden"
                    (click)="navigateKlotz('Klotz');"
                    id="picture3">
                <mat-card-content (click)="navigateKlotz('Klotz');"
                    id="unten">
                    <p>
                        Klötzchen/Lichtboxen
                    </p>
                </mat-card-content>
            </div>
        </div>
    </div>
</body>

</html>