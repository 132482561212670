<!DOCTYPE html>
<html lang="de"
  class="notranslate"
  translate="no">

<head>
  <meta charset="utf-8">
  <meta name="viewport"
    content="width=device-width, 
               initial-scale=1">
</head>

<body>
  <div class="allgemein">
    <div class="Überschrift">
      <h1>Bestellbestätigung</h1>
    </div>
    <mat-card class="example-card">
      <mat-card-content>
        <p>Vielen Dank für Ihre Bestellung bei Chrisubis-Hobby.de</p>
        <p>Sie erhalten eine Bestellbestätigung an folgende E-Mail Adresse: {{this.email}}</p>
      </mat-card-content>
      <mat-card-content *ngIf="this.zahlungsart === 'vorkasse'">
        <p>
          Überweisen Sie uns einfach den zu zahlenden Betrag. Sobald die Zahlung bei uns eingegangen ist,<br>
          versenden
          wir mit DHL an Sie mit Angabe der Sendungsverfolgungsnummer.<br>
          Bankverbindung:</p>
        <p>
          Sparkasse Vest Recklinghausen<br>
          Kontoinhaber: Christine Uhe<br>
          IBAN: DE 06 4265 0150 1000 6069 37<br>
          BIC: WELADED1REK<br>
          Bitte geben Sie unbedingt Ihren vollständigen Namen als Verwendungszweck an.<br>
        </p>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="flex-container">
    <div class="flex-item-unten-left">
      <button mat-button
        style="color:black;"
        (click)="navigatezurueck();">Zurück zum Shop</button>
    </div>
  </div>
</body>

</html>